import axios from "axios";
import localforage from "localforage";
import { extendPrototype } from "localforage-startswith";
extendPrototype(localforage);
import store from "../store";
const ACCURACY_THRESHOLD = 500;
const SURVEYEFFORT = "survey_effort";
const SURVEYBREADCRUMB = "survey_breadcrumb";
const SURVEYDOWNEDBIRD = "survey_downed_bird";
const SOSDOWNEDBIRD = "sos_downed_bird";
const SURVEYTURTLE = "survey_turtle";
const SURVEYPREDATOR = "survey_predator";
const SURVEYPREDATORPHOTO = "survey_photo_predator";
const SURVEYDOWNEDBIRDPHOTO = "survey_photo_downed_bird";
const SURVEYTURTLEPHOTO = "survey_photo_turtle";
const SURVEYEFFORTFIELDS = [
  { key: "id", type: "number" },
  { key: "incidental", type: "boolean" },
];
const SURVEYBREADCRUMBFIELDS = [
  { key: "id", type: "number" },
  { key: "longitude_wgs84", type: "number" },
  { key: "latitude_wgs84", type: "number" },
];
const SURVEYDOWNEDBIRDFIELDS = [
  { key: "id", type: "number" },
  { key: "longitude_wgs84", type: "number" },
  { key: "latitude_wgs84", type: "number" },
  { key: "field_species_code", label: "Species" },
  { key: "observation_ts", type: "text", label: "Timestamp" },
  { key: "ground_cover_height", type: "number" },
];
const SOSDOWNEDBIRDFIELDS = [{ key: "id", type: "number" }];
const SURVEYTURTLEFIELDS = [
  { key: "id", type: "number" },
  { key: "longitude_wgs84", type: "number" },
  { key: "latitude_wgs84", type: "number" },
  { key: "turtle_count", type: "number" },
  { key: "turtle_code", type: "text", label: "Code" },
  { key: "observation_ts", type: "text", label: "Timestamp" },
  { key: "tracks", type: "boolean" },
  { key: "nest", type: "boolean" },
  { key: "animal", type: "boolean" },
  { key: "false_crawl", type: "boolean" },
  { key: "distance_water", type: "number" },
];
const SURVEYPREDATORFIELDS = [
  { key: "id", type: "number" },
  { key: "longitude_wgs84", type: "number" },
  { key: "latitude_wgs84", type: "number" },
  { key: "predator_count", type: "number" },
  { key: "predator_code", type: "text", label: "Code" },
  { key: "predator_other", type: "text", label: "Predator Description" },
  { key: "predator_location", type: "text", label: "Location Description" },
  { key: "observation_ts", type: "text", label: "Timestamp" },
];
const PHOTOFIELDS = [{ key: "id", type: "number" }];
export default {
  SURVEYEFFORT: SURVEYEFFORT,
  SURVEYBREADCRUMB: SURVEYBREADCRUMB,
  SURVEYDOWNEDBIRD: SURVEYDOWNEDBIRD,
  SOSDOWNEDBIRD: SOSDOWNEDBIRD,
  SURVEYTURTLE: SURVEYTURTLE,
  SURVEYPREDATOR: SURVEYPREDATOR,
  SURVEYPREDATORPHOTO: SURVEYPREDATORPHOTO,
  SURVEYDOWNEDBIRDPHOTO: SURVEYDOWNEDBIRDPHOTO,
  SURVEYTURTLEPHOTO: SURVEYTURTLEPHOTO,
  SURVEYEFFORTFIELDS: SURVEYEFFORTFIELDS,
  SURVEYBREADCRUMBFIELDS: SURVEYBREADCRUMBFIELDS,
  SURVEYDOWNEDBIRDFIELDS: SURVEYDOWNEDBIRDFIELDS,
  SOSDOWNEDBIRDFIELDS: SOSDOWNEDBIRDFIELDS,
  SURVEYTURTLEFIELDS: SURVEYTURTLEFIELDS,
  SURVEYPREDATORFIELDS: SURVEYPREDATORFIELDS,
  PHOTOFIELDS: PHOTOFIELDS,
  submitter: [],
  required() {
    return [(v) => !!v || "Required"];
  },

  showMessage(msg) {
    alert(msg);
  },

  submit(submitInfo) {
    let endpoint;
    console.log("submitting data to database");
    if (!("key" in submitInfo)) {
      submitInfo.key = "p";
    }
    if ("endpoint" in submitInfo) {
      endpoint = store.state.dbName + submitInfo["endpoint"];
    } else {
      endpoint =
        store.state.dbName +
        "/public/" +
        submitInfo.table +
        "/keys/" +
        submitInfo.key +
        "/";
    }
    if (submitInfo.payload.length) {
      console.log(submitInfo.payload);
      axios
        .post(endpoint, JSON.stringify(submitInfo.payload))
        .then((response) => {
          // store.commit("setSubmitStatus", {
          //   tableName: submitInfo.table,
          //   value: false,
          // }); //gets set to false once an attempt was made, even if it failed
          if (response.status === 200) {
            let rowsInserted = 0;
            for (let i in response.data.message) {
              if (
                response.data.status[i] != "error" &&
                response.data.message[i].search("inserted") > -1
              ) {
                rowsInserted = rowsInserted + 1;
              }
            }
            store.commit("setUnsavedData", false);
          } else {
            // TODO: failure toast
            console.log("failure toast, response code = " + response.status);
          }
        })
        .catch((err) => {
          console.log("error posting", err);
          store.commit("setUnsavedData", true);
        });
    }
    //store.commit("setState", { varName: "rowsInserted", value: 0 });
  },

  getApiRefData: function (varName) {
    let endpoint = store.state.dbName + "/ref/" + varName + "/";
    this.getApiData(endpoint, varName, "refTables");
  },
  getDatabaseList: function () {
    let endpoint = "managed_databases/";
    this.getApiData(endpoint, "ref_hotel", null);
  },
  getViewApiData: function (varName) {
    let endpoint = store.state.dbName + "/public/" + varName + "/";
    this.getApiData(endpoint, varName, "views");
  },
  //retrieve data from backend API and store in store and localforage
  getApiData: function (endpoint, varName, holder) {
    let payload = { tableName: varName, tableType: holder };
    axios
      .get(endpoint)
      .then((response) => {
        (payload["records"] = response.data.sort(
          (a, b) => a.sequence - b.sequence
        )), //remove current localforage and replace
          this.deleteLocalTable(payload.tableName);
        this.saveRecords({
          table: payload.tableName,
          records: payload.records,
        });
        //write the records to store
        store.commit("refreshRecords", payload);
      })
      .catch((err) => {
        console.log(payload.tableName);
        console.log(err);
        if (payload.tableName.includes("ref")) {
          //can't connect so restore from local
          this.restoreRefFromLocal(payload.tableName);
        }
      });
  },
  async restoreFromLocal(tableName) {
    await localforage.startsWith(tableName).then((result) => {
      let records = this.forceFieldTypes(tableName, Object.values(result));
      store.commit("refreshRecords", {
        tableName: tableName,
        records: records,
        tableType: "dataTables",
      });
    });
  },
  async restoreRefFromLocal(tableName) {
    await localforage.startsWith(tableName).then((result) => {
      let records = this.forceFieldTypes(tableName, Object.values(result));
      store.commit("refreshRecords", {
        tableName: tableName,
        records: records,
        tableType: "refTables",
      });
    });
  },
  getFields(tableName) {
    switch (tableName) {
      case SURVEYEFFORT:
        return SURVEYEFFORTFIELDS;
      case SURVEYTURTLE:
        return SURVEYTURTLEFIELDS;
      case SURVEYPREDATOR:
        return SURVEYPREDATORFIELDS;
      case SURVEYDOWNEDBIRD:
        return SURVEYDOWNEDBIRDFIELDS;
      case SOSDOWNEDBIRD:
        return SOSDOWNEDBIRDFIELDS;
      case SURVEYBREADCRUMB:
        return SURVEYBREADCRUMBFIELDS;
      case SURVEYDOWNEDBIRDPHOTO || SURVEYPREDATORPHOTO || SURVEYTURTLEPHOTO:
        return PHOTOFIELDS;
    }
  },
  forceFieldTypes(tableName, records) {
    let header = this.getFields(tableName);
    for (let item in records) {
      try {
        records[item] = this.forceFieldType(header, records[item]);
      } catch (err) {
        console.log(err);
      }
    }
    return records;
  },
  forceFieldType(header, record) {
    for (let a in record) {
      if (record[a] != null && record[a].length < 1) {
        delete record[a];
      }
      if (
        header
          .filter(function (el) {
            return el["type"] === "number";
          })
          .map((a) => a.key)
          .includes(a)
      ) {
        record[a] = Number(record[a]);
      }
      if (
        header
          .filter(function (el) {
            return el["type"] === "boolean";
          })
          .map((a) => a.key)
          .includes(a)
      ) {
        record[a] = String(record[a]);
      }
    }
    return record;
  },
  //Delete records based on key matching tablename
  deleteLocalTable(tableName) {
    var myLength = tableName.length;
    Object.keys(localStorage).forEach(function (key) {
      if (key.substring(0, myLength) == tableName) {
        localStorage.removeItem(key);
      }
    });
  },
  //dt is a datetime of the start of the search interval
  findUnfinishedSearch(dt) {
    localforage.startsWith("ref_breadcrumb").then((bread) => {
      let all_breadcrumbs = Object.values(bread).length;
      localforage.startsWith("survey_effort").then((result) => {
        if (Object.values(result).length > 0) {
          let survey_records = Object.values(result)
            .filter(function (el) {
              return new Date(el["survey_ts"]).getDate() == dt.getDate();
            })
            .sort((a, b) => new Date(b.survey_ts) - new Date(a.survey_ts));
          let lastSurvey = survey_records.length > 0 ? survey_records[0] : null;
          if (lastSurvey != null) {
            localforage.startsWith("survey_breadcrumb").then((res) => {
              let breadcrumbs = Object.values(res).filter((el) => {
                return (el.survey_ts = lastSurvey.survey_ts);
              });
              if (
                breadcrumbs.length > 0 &&
                breadcrumbs.length < all_breadcrumbs
              ) {
                store.commit("setAttribute", {
                  attribute: "unfinishedSearch",
                  value: lastSurvey,
                });
              }
            });
          }
        }
      });
    });
  },
  //Save Records writes an array of record objects to local forage
  saveRecords(info) {
    console.log(info.records);
    info.records.map((r) => this.saveRecord(info.table, r));
  },
  saveRecord(tableName, record) {
    record.table = tableName;
    if (tableName.includes("ref")) {
      return this.saveReferenceTable(tableName, record);
    }
    switch (tableName) {
      case SURVEYDOWNEDBIRD:
        return this.saveObservationLocally(tableName, record);
      case SURVEYBREADCRUMB:
        return this.saveBreadCrumbLocally(record);
      case SURVEYEFFORT:
        return this.saveSurveyEffortLocally(record);
      case SURVEYTURTLE:
        return this.saveObservationLocally(tableName, record);
      case SURVEYPREDATOR:
        return this.saveObservationLocally(tableName, record);
    }
    console.log(record);
  },

  makeNewObservation(tabType) {
    var record;
    var table;
    switch (tabType) {
      case "Downed Bird":
        record = this.newDownedObservation();
        table = SURVEYDOWNEDBIRD;
        break;
      case "Turtle":
        record = this.newTurtleObservation();
        table = SURVEYTURTLE;
        break;
      case "Predator":
        record = this.newPredatorObservation();
        table = SURVEYPREDATOR;
        break;
    }
    record["observation_ts"] = this.getLocalTimestamp(new Date());
    store.commit("addRecord", { table: table, record: record });
    store.commit("shiftPosition", {
      table: table,
      position: store.state.dataTables[table].length - 1,
    });
  },
  newPredatorObservation() {
    let record = this.newSurveyObservation();

    record["predator_location"] = "";
    record["predator_code"] = "n/data";
    record["predator_count"] = 1;
    record["predator_other"] = "";
    record["observation_ts"] = "";
    return record;
  },
  newTurtleObservation() {
    let record = this.newSurveyObservation();
    record["turtle_location"] = "";
    record["turtle_code"] = "n/data";
    record["turtle_count"] = 1;
    record["observation_ts"] = "";
    record["tracks"] = false;
    record["nest"] = false;
    record["animal"] = false;
    record["false_crawl"] = false;
    record["distance_water"] = "";
    return record;
  },
  newSurveyObservation() {
    let record = {
      survey_tablet: store.getters.tabletCode,
      survey_ts: this.provideRecord(SURVEYEFFORT)["survey_ts"],
      latitude_wgs84: this.getLatitude(),
      longitude_wgs84: this.getLongitude(),
      comment: "",
    };
    return record;
  },
  newDownedObservation() {
    let record = this.newSurveyObservation();
    record["observation_ts"] = "";
    record["field_species_code"] = "";
    record["incident_ts"] = "";
    record["age_code"] = "U";
    record["sex_code"] = "U";
    record["drop_ts"] = "";
    record["location_description"] = "";
    record["fatality_injured_code"] = "";
    record["nearest_structure_desc"] = "";
    record["nearest_structure_type_code"] = "";
    record["ground_cover_type_code"] = "";
    record["ground_cover_height_cm"] = 0;
    record["current_precipitation_code"] = "";
    record["current_wind_speed_code"] = "";
    record["previous_precipitation_code"] = "";
    record["previous_wind_speed_code"] = "";
    record["current_wind_direction_code"] = "";
    record["previous_wind_direction_code"] = "";
    record["drop_location"] = "";
    record["specimen_condition"] = "";
    record["action_taken"] = "";
    record["pickup_person"] = "";
    record["incident_reporter"] = "";
    return record;
  },
  newBreadCrumb() {
    let record = {
      survey_tablet: store.getters.tabletCode,
      survey_ts: this.provideRecord(SURVEYEFFORT)["survey_ts"],
      breadcrumb_location_code: "",
      latitude_wgs84: this.getLatitude(),
      longitude_wgs84: this.getLongitude(),
      ts: "",
    };
    store.commit("addRecord", { table: SURVEYBREADCRUMB, record: record });
    store.commit("shiftPosition", {
      table: SURVEYBREADCRUMB,
      position: store.state.dataTables[SURVEYBREADCRUMB].length - 1,
    });
    return record;
  },
  newSurveyEffort() {
    let record = {
      //id: "",
      survey_tablet: store.getters.tabletCode,
      observer_code: store.getters.username,
      survey_ts: this.getLocalTimestamp(new Date()),
      second_searcher_code: "",
      reporter_name: "",
      description_of_omitted_: "",
      omission_reason: "",
      lighting_status_code: "",
      lighting_other: "",
      comment: "",
      incidental: false,
      observer_type: "searcher",
      insert_ts: "",
    };
    store.commit("addRecord", { table: SURVEYEFFORT, record: record });
    store.commit("shiftPosition", {
      table: SURVEYEFFORT,
      position: store.state.dataTables[SURVEYEFFORT].length - 1,
    });
    //child records change position as well
    store.commit("shiftPosition", {
      table: SURVEYDOWNEDBIRD,
      position: -1,
    });
    store.commit("shiftPosition", {
      table: SURVEYTURTLE,
      position: -1,
    });
    store.commit("shiftPosition", {
      table: SURVEYPREDATOR,
      position: -1,
    });
    return record;
  },
  saveLocal: async (key, record) => {
    return localforage
      .setItem(key, record)
      .then((value) => {
        console.log(value);
        return value;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  savePhotoLocally(table, photoData) {
    store.commit("addRecord", { table: table, record: photoData });
    let key = table + photoData.ts;
    this.saveLocal(key, photoData);
    const payload = {
      filename: photoData.photo_file_name,
      photoData: photoData.data,
    };
    this.submitSinglePhoto(payload);
  },
  saveObservationLocally(table, record) {
    let key =
      table + record.survey_ts + record.survey_tablet + record.observation_ts;
    this.saveLocal(key, record);
    localforage.getItem(key).then((res) => {
      console.log(res);
    });
  },
  saveSurveyEffortLocally(record) {
    let key = SURVEYEFFORT + record.survey_ts + record.survey_tablet;
    this.saveLocal(key, record);
    localforage.getItem(key).then((res) => {
      console.log(res);
    });
  },
  saveBreadCrumbLocally(record) {
    let key =
      SURVEYBREADCRUMB + record.survey_ts + record.survey_tablet + record.ts;
    this.saveLocal(key, record);
    localforage.getItem(key).then((res) => {
      console.log(res);
    });
  },

  saveReferenceTable: async (table, record) => {
    try {
      const value = await localforage.setItem(table + record.code, record);
      return value;
    } catch (err) {
      console.log(err);
      console.log("could not save reference record");
    }
  },
  hasCrumbs(surveyRecord) {
    let filtered = store.state.dataTables[SURVEYBREADCRUMB].filter(function (
      el
    ) {
      return (
        (el["survey_tablet"] === surveyRecord.survey_tablet) &
        (el["survey_ts"] === surveyRecord.survey_ts)
      );
    });
    console.log(filtered);
    return filtered.length > 0;
  },
  getLocalTimestamp: function (e) {
    let ts = new Date(e);
    return `${ts.getFullYear()}-${String(ts.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(ts.getDate()).padStart(2, "0")} ${String(
      ts.getHours()
    ).padStart(2, "0")}:${String(ts.getMinutes()).padStart(2, "0")}:${String(
      ts.getSeconds()
    ).padStart(2, "0")}`;
  },
  provideRecord(tableName) {
    if (store.state.tablePositions[tableName] == -1) {
      return this.newRecord(tableName);
    } else {
      return store.state.dataTables[tableName][
        store.state.tablePositions[tableName]
      ];
    }
  },
  findRecordPosition(table, record) {
    let records = store.state.dataTables[table];
    let index = -1;
    switch (table) {
      case SURVEYEFFORT:
        index = records.findIndex(
          (x) =>
            x.survey_tablet === record.survey_tablet &&
            new Date(x.survey_ts).toDateString() ===
              new Date(record.survey_ts).toDateString()
        );
        break;
    }
    return index;
  },
  setListPosition(table, position) {
    store.commit("shiftPosition", {
      table: table,
      position: position,
    });
  },
  newRecord(tableName) {
    switch (tableName) {
      case SURVEYDOWNEDBIRD:
        return this.newDownedObservation();
      case SURVEYBREADCRUMB:
        return this.newBreadCrumb();
      case SURVEYEFFORT:
        return this.newSurveyEffort();
      case SURVEYTURTLE:
        return this.newTurtleObservation();
      case SURVEYPREDATOR:
        return this.newPredatorObservation();
    }
  },
  populateReferenceTables() {
    Object.keys(store.state.refTables).forEach(async (key) => {
      this.getApiRefData(key);
    });
  },
  populateViews() {
    Object.keys(store.state.views).forEach(async (key) => {
      this.getViewApiData(key);
    });
  },
  submitData() {
    let tables = store.state.dataTables;
    this.submitRecords(SURVEYEFFORT);
    let submitter = Object.keys(tables).map(
      async (t) => await this.submitRecords(t)
    );
    console.log(submitter);
  },
  async submitRecords(table) {
    await localforage.startsWith(table).then((result) => {
      console.log(table);
      console.log(result);
      this.submit({
        table: table,
        key: "u",
        payload: this.forceFieldTypes(table, Object.values(result)),
      });
    });
  },
  submitSinglePhoto(payload) {
    const dbName = store.getters.dbName;
    axios.post(`${dbName}/write_photo/`, payload, {
      headers: { "Content-Type": "application/json" },
    });
  },
  startWatch: function () {
    let geoOptions = {
      enableHighAccuracy: true,
      maximumAge: 15000,
      timeout: 5000,
    };
    navigator.geolocation.getCurrentPosition(
      this.updateLocation,
      this.locationError,
      geoOptions
    );
    this.watchID = navigator.geolocation.watchPosition(
      this.updateLocation,
      this.locationError,
      geoOptions
    );
  },
  stopWatch: function () {
    navigator.geolocation.clearWatch(this.watchID);
  },
  turnGPSOn: function () {
    if (!store.state.gpsOn) {
      this.startWatch();
      store.commit("setGPSState", false);
    }
  },
  turnGPSOff: function () {
    if (store.state.gpsOn) {
      this.stopWatch();
      store.commit("setGPSState", false);
    }
  },
  gpsToggle: function () {
    if (store.state.gpsOn) {
      this.startWatch();
    } else {
      this.stopWatch();
    }
  },
  getLatitude() {
    if (store.state.coordinates != null) {
      return store.state.coordinates.latitude;
    } else {
      return 0.0;
    }
  },
  getLongitude() {
    if (store.state.coordinates != null) {
      return store.state.coordinates.longitude;
    } else {
      return 0.0;
    }
  },
  updateLocation: function (position) {
    if (position.coords.accuracy < ACCURACY_THRESHOLD) {
      store.commit("setCoordinates", position.coords);
    }
  },
  locationError: function (err) {
    console.log("location error: " + err.message);
  },
  asBoolean(strvalue) {
    if (strvalue == "true") {
      return true;
    }
    return false;
  },
  asString(boolval) {
    if (boolval) {
      return "true";
    }
    return "false";
  },
};
