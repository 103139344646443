<template>
  <div>
    <div class="d-flex">
      <!-- <v-btn icon to="/photo"> -->
      <!-- <v-btn icon @click="openCamera">
        <v-icon>mdi-camera</v-icon>
      </v-btn> -->
      <v-file-input
        v-model="selectedFile"
        class="pt-0 mr-2"
        type="file"
        accept="image/jpeg"
        id="photo-input"
        ref="photo-input"
        prepend-icon="mdi-camera"
        @change="onFileChange()"
        hide-input
      />
    </div>
    <canvas id="full-size-canvas" hidden />
    <v-data-table
      :headers="headers"
      :items="records"
      item-key="photo_file_name"
      sort-by="ts"
      group-by="photo_type_code"
      class="elevation-1"
      hide-default-footer
    ></v-data-table>
  </div>
</template>
<script>
import support from "../plugins/support";

export default {
  name: "Photo-List",
  components: {},
  props: {
    records: Array,
  },
  data() {
    return {
      selectedFile: "",
      handler: {},
      headers: [
        {
          value: "photo_file_name",
          text: "Photo Name",
        },
      ],
    };
  },
  methods: {
    openCamera() {
      this.$emit("cameraClicked");
    },
    //photo handling functions in photolist are only for uploaded photos.
    async onFileChange() {
      if (!this.selectedFile) {
        console.log("no file selected");
        return;
      }
      const file = this.selectedFile;
      const reader = new FileReader();
      //once the file is read we do things with it
      reader.onload = (e) => {
        this.imageData = e.target.result;
        //reformat so go can read the image data
        this.imageData = this.imageData.replace("data:image/jpeg;base64,", "");
        //.replace("data:image/octet-stream;base64,", "");
        let payload = {
          photo_file_name: this.setFileName(),
          data: this.imageData,
        };
        this.$emit("photoAccepted", payload);
        this.downloadPhoto(payload);
      };
      //start reading the file
      reader.readAsDataURL(file);
    },
    setFileName() {
      const d = new Date();
      return `${this.$store.getters.dbName}_${
        this.$store.getters.tabletCode
      }_${support
        .getLocalTimestamp(d)
        .replace(/ /g, "T")
        .replace(/:/g, "")}.jpeg`;
    },
    downloadPhoto(obj) {
      const filename = obj.photo_file_name;
      // old skool DOM manipulation, not great...
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = obj.data;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
      }, 0);
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}
.centered-input input {
  text-align: center;
}
</style>
