<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="needsDbName" persistent max-width="600px">
        <v-card>
          <v-card-title><span class="text-h5">Database Name</span></v-card-title
          ><span style="color: red"> {{ dbConnectError }} </span>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    v-if="hasHotelList == true"
                    :items="$store.state.ref_hotel"
                    item-text="title"
                    item-value="code"
                    label="Database"
                    filled
                    v-model="selectedDbName"
                    required
                    @input="databaseSelected"
                  ></v-select>
                  <v-text-field
                    v-else
                    label="Database"
                    v-model="selectedDbName"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="databaseSelected">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import localforage from "localforage";
import axios from "axios";
import support from "../plugins/support";

export default {
  name: "main-page",
  components: {},
  data: () => ({
    selectedDbName: null,
    dbConnectError: "",
  }),
  created() {
    this.$watch(
      "needsDbName",
      () => {
        if (!this.needsTablet && this.dbName != null && this.dbName != "") {
          this.$router.push({ name: "search" }).catch((e) => {
            console.log(e);
            this.dbConnectError = "";
          });
        } else if (this.dbName == null && this.needsDbName == false) {
          this.needsDbName = true;
          this.dbConnectError =
            "Could not connect to the database. \n Please check your network and try again.";
        }
      },
      { deep: true }
    );
    this.$watch(
      "needsTablet",
      () => {
        if (!this.needsDbName && this.dbName != null) {
          this.$router.push({ name: "search" }).catch((e) => e);
        }
      },
      { deep: true }
    );
    this.$watch(
      "dbName",
      () => {
        if (this.dbName && this.dbName.length > 0) {
          this.onDbNameSet();
          support.populateReferenceTables();
          if (!this.needsDbName) {
            this.$router.push({ name: "search" }).catch((e) => e);
          }
        }
      },
      { deep: true }
    );
  },
  async mounted() {
    support.getDatabaseList();
    if (this.needsTablet) {
      this.$store.dispatch("setTabletCode");
    }
    this.dbName = this.$store.getters.dbName;
    if (this.dbName && this.dbName.length > 0) {
      this.onDbNameSet();
    }
  },
  computed: {
    needsDbName() {
      return !(this.dbName !== null && this.dbName.length > 1);
    },
    dbName: {
      get() {
        return this.$store.getters.dbName;
      },
      set(value) {
        this.$store.commit("setDbName", value);
      },
    },
    needsTablet() {
      return this.$store.getters.tabletCode == null;
    },
    hasHotelList() {
      return this.$store.getters.refHotel.length > 0;
    },
  },
  methods: {
    // hasHotelList() {
    //   return this.$store.state.ref_hotel.length > 0;
    // },
    databaseSelected() {
      this.dbName = this.selectedDbName;
    },
    setDbName: async function () {
      console.log("in setDbName");
      if (this.dbName != null && this.dbName.length > 0) {
        let db = this.dbName;
        await localforage.getItem("dbName").then(function (res) {
          console.log("database was: ", res);
          if (res != db) {
            localforage.clear();
          }
        });
        await localforage.setItem("dbName", this.dbName);
        this.$store.dispatch("setDbName", this.dbName).then(() => {
          support.populateReferenceTables();
        });
      }
    },
    onDbNameSet: async function () {
      await axios.get(`${this.dbName}/logged_in`).catch((error) => {
        //resetting because error connecting to database
        console.error(error);
        this.$store.dispatch("setDbName", this.dbName);
      });
    },
  },
};
</script>

<style>
#err {
  font-weight: bold;
  color: #ee0a0a;
}
</style>
